<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '../../composables/useServices';
import { brandDomainSuffix, brandIds } from '~/assets/data/config';

const auth = useAuthStore();
const { $walletService } = useServices();
const siteStore = useSiteStore();
const config = useRuntimeConfig();

interface BankingProps {
  type?: string;
}
const props = withDefaults(defineProps<BankingProps>(), {
  type: '',
});

onMounted(() => {
  if (window.onmessage == null) {
    window.onmessage = (event) => {
      attachMessage(event);
    };
  }
});

//gavin to do BEGIN
function url() {
  return `https://${document.location.hostname}/banking-app/${
    props.type
  }/?msisdn=${
    auth.currentUser.primaryMobileNumber
  }&from=jpc&locale=en-US&theme=${useColorMode().preference}`;
}

const refreshUserBalances = async () => {
  await $walletService.fetchBalances();
};

function tempData() {
  //all these need to be set to logged user data. // TODO
  return {
    UserData: {
      Method: 'setToken',
      Msisdn: auth.currentUser.username,
      BrandId: brandIds[siteStore.getRegionCode],
      Id: auth.userId,
      CommunicationChannel: 0,
      Token: auth.currentAccessToken,
    },
  };
}
//gavin to do END

function attachMessageEvent() {
  if (window.onmessage == null) {
    window.onmessage = (event) => {
      attachMessage(event);
    };
  }
}

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME';

function attachMessage(event: any) {
  const bankingType = props.type;
  const bankingTypeResize = props.type.toLowerCase() + 's';
  const bankingFrameObject = document.querySelector('[id*=banking_frame]');
  let bankingFrameName = '';
  if (bankingFrameObject != null) {
    bankingFrameName = bankingFrameObject.id;
  }

  if (event.data.toString().includes('showSynapseMessage')) {
    const synapseMessageData = JSON.parse(event.data);
    // showSynapseAlert({
    //   title: synapseMessageData.title,
    //   text: synapseMessageData.text,
    //   status: synapseMessageData.status,
    // });
  }

  if (event.data.toString().includes('refreshBalance')) {
    refreshBalances();
  }

  if (event.data.toString().includes('openChat')) {
    settings.toggleFeature({ feature: 'displayLiveChat' });
  }

  if (event.data.toString().includes('toggleAccountModalOn')) {
    // toggleAccountModal(true);
  }

  if (event.data.toString().includes('toggleAccountModalOff')) {
    // toggleAccountModal(false);
  }

  if (event.data.toString().includes('loadedIframe')) {
    let frame = document.getElementById(bankingFrameName);
    if (isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage('loaded' + bankingType + 'Resize', '*');
    }

    if (isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage(JSON.stringify(tempData()), '*');
    }
  }
  if (event.data.toString().includes('setAccountData')) {
    let frame = document.getElementById(bankingFrameName);
    if (isIFrame(frame) && frame.contentWindow) {
      frame.contentWindow.postMessage(JSON.stringify(tempData()), '*');
    }
  }
  if (event.data.toString().includes('heighter' + bankingTypeResize)) {
    const newHeight =
      parseInt(event.data.replace('heighter' + bankingTypeResize + ':', '')) +
      'px';

    let frame = document.getElementById(bankingFrameName);
    if (isIFrame(frame) && frame.contentWindow) {
      frame.style.height = newHeight;
      frame.style.maxHeight = newHeight;
    }
  }

  if (event.data.toString().includes('changeIframeHeight')) {
    const jsonObject = JSON.parse(event.data.toString());
    const newHeight = jsonObject.newHeight + 'px';
    const frameName = jsonObject.frameName;

    let frame = document.getElementById(frameName);
    if (isIFrame(frame) && frame.contentWindow) {
      frame.style.height = newHeight;
      frame.style.maxHeight = newHeight;
    }
  }

  showDepositSpecific(event.data, bankingFrameName);
  showWithdrawalSpecific(event.data);
}

function showDepositSpecific(data: any, bankingFrameName: string) {
  if (data.type === 'setdepositiframe') {
    let frame = document.getElementById(bankingFrameName);
    if (isIFrame(frame) && frame.contentWindow) {
      frame.setAttribute('scrolling', 'auto');
    }

    let vwindow = document.getElementById('.v-window');
    if (isIFrame(frame) && frame.contentWindow) {
      frame.style.overflow = 'hidden';
    }
  }
  let message;
  if (data.type === 'refreshdepositbalance') {
    message = 'You have successfully deposit money.';
    if (data.alert === 'warning') {
      message = 'You have cancelled your deposit money request.';
    } else if (data.alert === 'error') {
      message = 'Your deposits request have failed';
    }

    showSynapseAlert({
      title: 'Deposit Status',
      text: message,
      status: data.alert,
    });

    toggleAccountModal(false);
    refreshBalances();
  }

  if (data.type === 'refreshdepositbalanceMM') {
    toggleAccountModal(false);
    refreshBalances();
  }
}
function showWithdrawalSpecific(data: any) {
  if (data.type === 'withdrawrefreshbalance') {
    showSynapseAlert({
      title: 'Successful Withdrawal',
      text: 'Your withdraw transaction was succcesful.',
      status: 'success',
    });

    refreshBalances();
    toggleAccountModal(false);
  }
}
async function refreshBalances() {
  try {
    await refreshUserDetails();
  } catch (e) {
    console.error(e);
  }
}
function showSynapseAlert(obj: any) {
  console.log('showSynapseAlert', obj);
}

function toggleAccountModal(obj: any) {
  console.log('toggleAccountModal', obj);
}

function refreshUserDetails() {
  refreshUserBalances();
}
</script>
<template>
  <iframe
    :id="'banking_frame' + type"
    :key="type"
    :src="url()"
    class="w-full max-view-width banking_frame border-0 mt-2"
    marginwidth="0"
    marginheight="0"
    frameborder="0"
    scrolling="yes"
    allowtransparency="true"
  />
</template>

<style lang="scss" scoped>
.banking_frame {
  min-height: 550px;
  padding-bottom: 10px;
}
</style>
